<template>
	<v-card flat color="secondary">
		<v-card-text>
			<emb-perfect-scrollbar class="scroll-area" style="height: 70vh;">
				<v-row dense>
					<v-col
						cols="12"
						xl="1"
						lg="2"
						md="2"
						sm="3"
						class="mb-0"
						v-for="(table, i) in tables"
						:key="i"
					>
						<v-card
							:color="
								!table.free
									? table.printed_pre_account
										? 'primary lighten-3'
										: 'accent lighten-1'
									: 'success'
							"
							hover
							dark
						>
							<el-tooltip
								class="item"
								effect="dark"
								content="Imprimir Pre-Cuenta"
								placement="top"
							>
								<i
									v-if="table.command_id"
									class="el-icon-printer float-left ma-2"
									@click.prevent="
										clickPrintPreAccount(table.command_id, table.salon_id)
									"
								>
								</i>
							</el-tooltip>
							<el-tooltip
								class="item"
								effect="dark"
								content="Cancelar pedido"
								placement="top"
							>
								<i
									v-if="table.command_id"
									class="el-icon-delete float-right ma-2"
									@click.prevent="
										clickCancelCommand(table.command_id, table.salon_id)
									"
								>
								</i>
							</el-tooltip>
							<div @click.prevent="clickAddCommand(table.id)">
								<h6 class="text-center white--text py-2">{{ table.name }}</h6>
								<h5 class="text-center">
									<v-chip color="dark" text-color="white" small>
										{{ table.free ? 'VACÍO' : table.total }}
									</v-chip>
								</h5>
								<p class="text-center caption white--text mb-0">Mozo: {{ table.waiter }}</p>
								<p class="text-center caption white--text mb-0 pb-2">
									<v-icon>mdi-timer</v-icon> {{ table.date_of_command }}
								</p>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</emb-perfect-scrollbar>
			<v-row>
				<v-chip
					x-small
					class="mt-2 mr-1"
					color="success"
					label
					text-color="white"
				>
					Mesa libre
				</v-chip>
				<v-chip x-small class="mt-2 mx-1" color="accent lighten-1" label text-color="white">
					Mesa ocupada
				</v-chip>
				<v-chip
					x-small
					class="mt-2 mx-1"
					color="primary lighten-3"
					label
					text-color="white"
				>
					Pre-Cuenta impresa
				</v-chip>

				<v-spacer></v-spacer>

				<v-chip
					x-small
					class="mt-2 mx-1"
					color="accent"
					label
					text-color="white"
					v-for="(salon, index) in salons"
					@click.prevent="getTablesBySalon(salon.id)"
					:key="index"
				>
					{{ salon.name }}
				</v-chip>
			</v-row>
			<!-- <v-tabs fixed-tabs centered background-color="primary" dark class="rounded">
				<v-tab
					v-for="(salon, index) in salons"
					@click.prevent="getTablesBySalon(salon.id)"
					:key="index"
				>
					{{ salon.name }}
				</v-tab>
			</v-tabs> -->
		</v-card-text>
	</v-card>
</template>

<script>
import { deletable } from 'Mixins/deletable';
import { confirm } from 'Mixins/confirm';

export default {
	mixins: [deletable, confirm],
	data() {
		return {
			resource: 'commands',
			salons: [],
			tables: [],
			tab: null,
			intervalID: '',
			currentSalonID: null,
		};
	},
	
	async created() {
		await this.getTables();
	},
	// mounted: function() {
    // 	this.reloadRooms();
  	// },
  	// beforeDestroy() {
    // 	clearInterval(this.intervalID);
  	// },
	methods: {
		reloadRooms: function() {
			this.intervalID = setInterval(
				function() {
				this.getTablesBySalon(this.currentSalonID);
				}.bind(this),
				20000
			);
		},
		getTables() {
			if (localStorage.getItem('salons')) {
				this.salons = JSON.parse(localStorage.getItem('salons'));
				this.getTablesBySalon(this.salons[0].id);
				this.currentSalonID = this.salons[0].id;
			} else {
				this.$http.get(`/${this.resource}/salons`).then((response) => {
	
					this.salons = response.data;
					if (this.salons.length > 0) {
						this.getTablesBySalon(this.salons[0].id);
						this.currentSalonID = this.salons[0].id;
						localStorage.setItem('salons', JSON.stringify(this.salons));
					}
				});
			}
		},
		getTablesBySalon(salonId) {
			this.currentSalonID = salonId;
			this.$http.get(`/${this.resource}/tables/by-salon/${salonId}`).then((response) => {
				this.tables = response.data;
			});
		},
		clickAddCommand(tableId) {
			this.$router.push({ name: 'SaleAdd', params: { id: tableId } });
		},
		clickCancelCommand(id, salon_id) {
			this.close(`/${this.resource}/cancelled/${id}`).then(() => {
				this.getTablesBySalon(salon_id);
			});
		},
		clickPrintPreAccount(id, salon_id) {
			this.confirm().then(() => {
				this.$http.get(`/${this.resource}/print/pre-account/${id}`).then((response) => {
					if (response.data.success) {
						let data = JSON.stringify(response.data.data);
						let url = `https://print-api.test/print/pre-account`;

						fetch(url, { method: 'POST', body: data })
							.then((dataWrappedByPromise) => dataWrappedByPromise.json())
							.then((data) => {
								if (data.success) {
									this.$message.success(data.message);
								} else {
									this.$message.error(data.message);
								}
							})
							.catch((error) => {
								console.error('Error: ', error);
							});
						this.getTablesBySalon(salon_id);
					} else {
						this.$message.error(response.data.message);
					}
				});
			});
		},
	},
};
</script>
